import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


let initialStore = {
  loggedIn: "",
  propertyDetails: {},
  // nextScreen:'property',
  nextScreen:'property',
  contractorDetails:{},
  ownerDetails:{},
  systemDetails:{},
  formDetails:{},
  aesDetails:[]
};



const dataSplice = createSlice({
  name: "data",
  immer: false,
  initialState: initialStore,
  reducers: {
    propertyDetails:(state,action)=>{
      
    state.propertyDetails=action.payload
    },
    contractorDetails:(state,action)=>{
       
    state.contractorDetails=action.payload
    },
    ownerDetails:(state,action)=>{
      
    state.ownerDetails=action.payload
    },
    systemDetails:(state,action)=>{
      
    state.systemDetails=action.payload
    },
    formDetails:(state,action)=>{
       
    state.formDetails=action.payload
    },
    aesDetails:(state,action)=>{
     
    state.aesDetails=action.payload
    },
    ChangenextScreen:(state,action)=>{
      
        state.nextScreen= action.payload
      
    }
  },
  extraReducers: (builder) => {
  
  },
});


export const {propertyDetails} = dataSplice.actions;
export const {contractorDetails} = dataSplice.actions;
export const {ownerDetails} = dataSplice.actions;
export const {systemDetails} = dataSplice.actions;
export const {formDetails} = dataSplice.actions;
export const {aesDetails} = dataSplice.actions;
export const {ChangenextScreen} = dataSplice.actions;



export const propDetails=(state)=>state.data.propertyDetails;
export const contractDetails=(state)=>state.data.contractorDetails;
export const ownDetails=(state)=>state.data.ownerDetails;
export const sysDetails=(state)=>state.data.systemDetails;
export const formDetail=(state)=>state.data.formDetails;
export const aesDetail=(state)=>state.data.aesDetails;
export const next=(state)=>state.data.nextScreen;

export default dataSplice.reducer;
