import { useState, useRef, useEffect } from "react";
// import storage from "./firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../styles/Starter.css";
import {
  Button,
  Typography,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import {
  ChangenextScreen,
  contractDetails,
  contractorDetails,
  next,
  propertyDetails,
} from "../reducers/dataSplice";
// import { CheckBox } from "@mui/icons-material";

function ContractorInfo() {
  const [contractorInfo, setContractorInformation] = useState({
    "name": "AZ Builders",
    "address": "27, Power Lane",
    "city": "Mysore",
    "state": "Karnataka",
    "telephone": "+91 88191 45625",
    "caLicenseId": "CARF-X56172",
    "jobId": "XCD-617278",
    "performedBy": "Mr. Hrishi K"
  });
  const nextState = useSelector(next);
  // const navigate=useNavigate();
  const [valid, setValid] = useState("");
  const dispatch = useDispatch();
  const contrDetail = useSelector(contractDetails)
  // Handles input change event and updates state

  const ChangeHandler = (text, key, value) => {
    if (text === "contractorInfo")
      setContractorInformation({ ...contractorInfo, [key]: value });
  };

  const validate = async () => {
    if (
      contractorInfo.name === "" ||
      contractorInfo.address === "" ||
      contractorInfo.city === "" ||
      contractorInfo.state === "" ||
      contractorInfo.telephone === "" ||
      contractorInfo.caLicenseId === "" ||
      contractorInfo.jobId === "" ||
      contractorInfo.performedBy === "" 
    ) {
      setValid("error");
    } else {

      dispatch(contractorDetails({ ...contractorInfo }));
      dispatch(ChangenextScreen("owner"));
    }
  };

    useEffect(()=>{
if(!_.isEmpty(contrDetail))
{
setContractorInformation(contrDetail)
}

  },[])

  return (
    <>
      {" "}
      {nextState == "contractor" && (
        <div className="div-form">
          <Typography id="modal-modal-title" variant="h6" style={{color:'#70f92c'}}>
            Contractor Information
          </Typography>
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.name === ""}
            helperText={
              valid === "error" &&
              contractorInfo.name === "" &&
              "Enter valid name"
            }
            required
            className="textfield"
            label="Name"
            variant="outlined"
            value={contractorInfo.name}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "name", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.address === ""}
            helperText={
              valid === "error" &&
              contractorInfo.address === "" &&
              "Enter valid address"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Address"
            variant="outlined"
            value={contractorInfo.address}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "address", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.city === ""}
            helperText={
              valid === "error" &&
              contractorInfo.city === "" &&
              "Enter valid city"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="City"
            variant="outlined"
            value={contractorInfo.city}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "city", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.state === ""}
            helperText={
              valid === "error" &&
              contractorInfo.state === "" &&
              "Enter valid state"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="State"
            variant="outlined"
            value={contractorInfo.state}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "state", e.target.value)
            }
          />
          <TextField
            size="small"
          
            error={valid === "error" && contractorInfo.telephone === ""}
            helperText={
              valid === "error" &&
              contractorInfo.telephone === "" &&
              "Enter valid telephone number"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Telephone"
            variant="outlined"
            value={contractorInfo.telephone}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "telephone", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.caLicenseId === ""}
            helperText={
              valid === "error" &&
              contractorInfo.caLicenseId === "" &&
              "Enter valid caLicenseId number"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="caLicenseId"
            variant="outlined"
            value={contractorInfo.caLicenseId}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "caLicenseId", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.jobId === ""}
            helperText={
              valid === "error" &&
              contractorInfo.jobId === "" &&
              "Enter valid jobId number"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="jobId"
            variant="outlined"
            value={contractorInfo.jobId}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "jobId", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && contractorInfo.performedBy === ""}
            helperText={
              valid === "error" &&
              contractorInfo.performedBy === "" &&
              "Enter valid performedBy number"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="performedBy"
            variant="outlined"
            value={contractorInfo.performedBy}
            onChange={(e) =>
              ChangeHandler("contractorInfo", "performedBy", e.target.value)
            }
          />
          <div style={{display:'flex', flexDirection:'row',margin:'auto',position:'absolute', bottom:10, right:0}}>
          <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px'}} onClick={() => dispatch(ChangenextScreen("property"))}>
            Back
          </Button>
          <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'0.5em'}} onClick={() => validate()}>Next</Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ContractorInfo;
