import { useState, useRef, useEffect } from "react";
// import storage from "./firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../styles/Starter.css";
import {
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Modal,
  TextField,
  Checkbox,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
// import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
// import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReplayIcon from "@mui/icons-material/Replay";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Data from "./Data";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  next,
  propDetails,
  propertyDetails,
  contractDetails,
  sysDetails,
  ownDetails,
  formDetail,
  aesDetail,
  ChangenextScreen,
} from "../reducers/dataSplice";
import PropertyInfo from "./PropertyInfo";
import ContractorInfo from "./ContractorInfo";
import OwnerInfo from "./Owner";
import SystemInfo from "./SystemInfo";
import FormInfo from "./FormInfo";
import AesInfo from "./AesInfo";
// import { CheckBox } from "@mui/icons-material";

function Starter() {
  const [status, setStatus] = useState("edit");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");
  const dispatch = useDispatch();
  const propertyInformation = useSelector(propDetails);
  const contractorInformation = useSelector(contractDetails);
  const ownerInformation = useSelector(ownDetails);
  const systemRisers = useSelector(sysDetails);
  const formsIncluded = useSelector(formDetail);
  const AESForm = useSelector(aesDetail);
  const [timer, setTimer] = useState(false);
  let body = "";
  const nextState = useSelector(next);
  const navigate = useNavigate();
  const [downloadLink, setDownloadLink] = useState("");
  const makeTextFile = () => {
    // This creates the file.
    // In my case, I have an array, and each item in
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    const data = new Blob(propertyInformation, { type: "text/plain" });

    // this part avoids memory leaks
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    // update the download link state
    setDownloadLink(window.URL.createObjectURL(propertyInformation));
  };

  // Call the function if list changes
  // useEffect(() => {
  //   makeTextFile()
  // }, [propertyInformation])

  // Handles input change event and updates state
  const fetchData = async () => {
    try {
      !loading && downloadURL === "" && setLoading(true);
      let x = await axios.post("https://pdf-filler.datasahi.com/fill", body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTimeout(() => {
        setTimer(true);
      }, 120000);
      if (x.data) {
        setDownloadURL(x.data.filledFile);
        (loading || downloadURL !== "") && setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const downloadData = () => {
    const a = document.createElement("a");
    var blob = new Blob([`${localStorage.getItem("datajson")}`], {
      type: "text/plain;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob); // Create an object URL from blob
    a.setAttribute("href", url); // Set "a" element link
    a.setAttribute("download", "data.json"); // Set download filename
    a.click(); // Start downloading
  };

  const submit = () => {
    let payload = {
      propertyInformation,
      contractorInformation,
      ownerInformation,
      systemRisers,
      formsIncluded,
      AESForm,
    };
    localStorage.setItem("datajson", JSON.stringify(payload));
    // console.log(localStorage.getItem("datajson"));
    let payload2 = JSON.stringify(payload);
    body = JSON.stringify(payload2);
    fetchData();
  };

  return (
    <div className="container">
      <h2>PDF Filler</h2>

      <div className="pdf-button">
        <Button
          sx={{
            color: "#000",
            fontSize: "18px",
            fontWeight: "500",
            width: "250px",
          }}
          onClick={() => navigate("/data")}
        >
          Sample PDF
        </Button>
        {/* upload button */}
      </div>
      <div className="button-container">
        <Modal
          sx={{ backgroundColor: "#fff" }}
          className="modal-container"
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className={
              nextState === "aes" || nextState === "final"
                ? "modal-container-aes"
                : "modal-container"
            }
          >
            {status === "property" && (
              <div className="div-form">
                <PropertyInfo />
              </div>
            )}
            {(status === "contractor" || nextState === "contractor") && (
              <div className="div-form">
                <ContractorInfo />
              </div>
            )}

            {(status === "owner" || nextState === "owner") && (
              <div>
                <OwnerInfo />
              </div>
            )}

            {nextState === "system" && (
              <div>
                <SystemInfo />
              </div>
            )}

            {nextState === "form" && (
              <div>
                <FormInfo />
              </div>
            )}

            {nextState === "aes" && (
              <div>
                <AesInfo />
              </div>
            )}

            {nextState === "final" && (
              <div>
                <h3 style={{ marginLeft: "25em" }}>Edited Data json</h3>
                {
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <table>
                      <thead style={{ fontSize: "14px" }}>
                        PropertyInformation:{"{"}
                      </thead>
                      <tbody>
                        {Object.keys(propertyInformation).map((i, k) => (
                          <tr>
                            {k < 9 ? (
                              <td
                                style={{
                                  fontSize: "12px",
                                  paddingBottom: "-1.5em",
                                }}
                              >
                                "{i}":"{propertyInformation[i]}",
                              </td>
                            ) : (
                              <td style={{ fontSize: "12px" }}>
                                "{i}":"{propertyInformation[i]}"{"}"}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead style={{ fontSize: "14px" }}>
                        contractorInformation:{"{"}
                      </thead>
                      <tbody>
                        {Object.keys(contractorInformation).map((i, k) => (
                          <tr>
                            {k < 7 ? (
                              <td
                                style={{
                                  fontSize: "12px",
                                  paddingBottom: "-1.5em",
                                }}
                              >
                                "{i}":"{contractorInformation[i]}",
                              </td>
                            ) : (
                              <td style={{ fontSize: "12px" }}>
                                "{i}":"{contractorInformation[i]}"{"}"}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead style={{ fontSize: "14px" }}>
                        ownerInformation:{"{"}
                      </thead>
                      <tbody>
                        {Object.keys(ownerInformation).map((i, k) => (
                          <tr>
                            {k < 7 ? (
                              <td
                                style={{
                                  fontSize: "12px",
                                  paddingBottom: "-1.5em",
                                }}
                              >
                                "{i}":"{ownerInformation[i]}",
                              </td>
                            ) : (
                              <td style={{ fontSize: "12px" }}>
                                "{i}":"{ownerInformation[i]}"{"}"}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead style={{ fontSize: "14px" }}>
                        systemRisers:{"{"}
                      </thead>
                      <tbody>
                        {Object.keys(systemRisers).map((i, k) => (
                          <tr>
                            {k < 5 ? (
                              <td
                                style={{
                                  fontSize: "12px",
                                  paddingBottom: "-1.5em",
                                }}
                              >
                                "{i}":"{systemRisers[i]}",
                              </td>
                            ) : (
                              <td style={{ fontSize: "12px" }}>
                                "{i}":"{systemRisers[i]}"{"}"}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead style={{ fontSize: "14px" }}>
                        formsIncluded:{"{"}
                      </thead>
                      <tbody>
                        {Object.keys(formsIncluded).map((i, k) => (
                          <tr>
                            {k < 5 ? (
                              <td
                                style={{
                                  fontSize: "12px",
                                  paddingBottom: "-1.5em",
                                }}
                              >
                                "{i}":"{formsIncluded[i]}",
                              </td>
                            ) : (
                              <td style={{ fontSize: "12px" }}>
                                "{i}":"{formsIncluded[i]}"{"}"}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table>
                      <thead style={{ fontSize: "14px" }}>AESForm:{"{"}</thead>
                      <tbody>
                        {AESForm.map((i) =>
                          Object.keys(i).map((val, k) => (
                            <tr>
                              {k < 4 ? (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    paddingBottom: "-1.5em",
                                  }}
                                >
                                  "{val}":"{i[val]}",
                                </td>
                              ) : (
                                <td style={{ fontSize: "12px" }}>
                                  "{val}":"{i[val]}"{"]"}
                                </td>
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                }
                <div style={{ margin: "auto", width: "350px" }}>
                  <Button
                    sx={{
                      border: "1px solid #7EC4F8",
                      marginTop: "3em",
                      marginLeft: "1em",
                      width: "100px",
                    }}
                    onClick={() => dispatch(ChangenextScreen("aes"))}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{
                      border: "1px solid #7EC4F8",
                      marginTop: "3em",
                      marginLeft: "1em",
                    }}
                    onClick={() => {
                      submit();
                      setModalVisible(false);
                    }}
                  >
                    Generate PDF
                  </Button>
                </div>
              </div>
            )}
            {/* submit() */}
          </Box>
        </Modal>
        <div
          className="upload-button-div"
          style={{
            marginTop: "3em",
            marginLeft: "-4.25em",
            // backgroundColor: status === "edit" ? "" : "#BDDFFA",
            cursor: status === "upload" ? "" : "not-allowed",
          }}
        >
          <Button
            onClick={() => {
              setModalVisible(true);
              setStatus("property");
              setDownloadURL('');
              //  navigate('/data')
            }}
            className="upload-button"
            // disabled={status !== "edit"}
            sx={{
              color: "#000",
              textTransform: "capitalize",
              fontSize: "18px",
            }}
          >
            <UploadFileIcon /> Edit data
          </Button>
        </div>

        <div
          style={{
            marginTop: "3em",
            // backgroundColor: status === "converted" ? "" : "#BDDFFA",
            color: status === "converted" ? "#000" : "#D3D3D3",
            cursor: status === "converted" ? "" : "not-allowed",
            height: "50px",
            border: "2px solid #70f92c",
            marginLeft: "1em",
          }}
          className="upload-button"
        >
          {/* {localStorage.getItem("datajson") && ( */}
            <Button
              sx={{
                textTransform: "capitalize",
                fontSize: "18px",
                width: "250px",
                color: "#000",
              }}
              // disabled={!loading || downloadURL === ""}
              disabled={ downloadURL === ""}
              className="upload-button"
              onClick={() => downloadData()}
            >
              <DownloadOutlinedIcon /> Download edited data
            </Button>
          {/* )} */}
        </div>
        {/* download button */}
        <div
          style={{
            marginTop: "3em",
            // backgroundColor: status === "converted" ? "" : "#BDDFFA",
            color: status === "converted" ? "#000" : "#D3D3D3",
            cursor: status === "converted" ? "" : "not-allowed",
            marginLeft: "1em",
          }}
          className="upload-button-div"
        >
          <Button
            className="upload-button"
            disabled={downloadURL === ""}
            onClick={() => {
              window.location.href = downloadURL;
            }}
            sx={{
              color: "#000",
              textTransform: "capitalize",
              fontSize: "18px",
            }}
          >
            <DownloadOutlinedIcon />
            Download edited PDF
          </Button>
        </div>
      </div>

      {loading && downloadURL === "" && <CircularProgress />}
      {timer && downloadURL === "" && (
        <div style={{ marginTop: "3em" }} className="upload-button">
          <span className="error-message">Error in converting the pdf</span>
          <Button
            className="upload-button"
            onClick={() => window.location.reload()}
            sx={{
              color: "#FFF",
              textTransform: "capitalize",
              fontSize: "18px",
            }}
          >
            <ReplayIcon />
            Retry
          </Button>
        </div>
      )}
      <Card
        className="card-stylings-use"
        sx={{ boxShadow: "none", marginTop: "4em" }}
      >
        <CardContent
          sx={{
            border: "none",
            marginTop: status === "converting" ? "4em" : "",
            fontWeight: "600",
            fontSize: "17px",
          }}
        >
          <TipsAndUpdatesOutlinedIcon />
          <br />
          How to use ?
          <Typography sx={{ fontSize: "16px" }}>
            1. View sample data.json and edit it if required <br />
            2. Verify the edited data and click on Generate pdf <br />
            3. Easily access the edited data.json & PDF by downloading it to
            your computer
          </Typography>
        </CardContent>
      </Card>

      <div className="features">
        <Card
          className="card-stylings"
          sx={{ boxShadow: "none", fontWeight: "600", fontSize: "17px" }}
        >
          <CardContent sx={{ border: "none" }}>
            <SecurityOutlinedIcon />
            <br />
            Safe and secure
            <Typography sx={{ fontSize: "14px" }}>
              Once you've reached our site, you can be certain that data and
              files are safe and remain confidential.
            </Typography>
          </CardContent>
        </Card>

        {/* <Card
          className="card-stylings"
          sx={{ boxShadow: "none" }}
          // sx={{ width: '300px', border:'none',borderBottomColor:'transparent', margin:'auto', boxShadow: "none" }}
        >
          <CardContent
            sx={{ border: "none", fontWeight: "600", fontSize: "17px" }}
          >
            <BoltOutlinedIcon />
            <br />
            Fast
            <Typography sx={{ fontSize: "14px" }}>
              Not only is our PDFfiller user-friendly but also outstandingly
              fast.
            </Typography>
          </CardContent>
        </Card> */}

        <Card
          className="card-stylings"
          sx={{ boxShadow: "none" }}
          // sx={{ width: '300px', border:'none',borderBottomColor:'transparent', margin:'auto', boxShadow: "none" }}
        >
          <CardContent
            sx={{ border: "none", fontWeight: "600", fontSize: "17px" }}
          >
            <CheckCircleOutlineIcon />
            <br />
            Universal conversion
            <Typography sx={{ fontSize: "14px" }}>
              Our converter works on any OS, including Windows, Mac, and Linux
              as long as you have an Internet connection.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className="footer">
        © Copyright 2022{" "}
        <a
          href="https://91social.com"
          target="blank"
          style={{ color: "#dd0c4e" }}
        >
          91Social
        </a>{" "}
        all rights reserved
      </div>
    </div>
  );
}

export default Starter;
