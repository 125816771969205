import React from "react";
import '../styles/Data.css';
import { TextField } from "@mui/material";

const LeftComponent=()=>{
return(
    <React.Fragment>
       {
       <div>
       <h3>Data.json</h3>
    <p style={{fontSize:'12px'}}>  {"{"}
  "propertyInformation": {"{"}<br/>
    "name": "Honeywell Building 101",<br/>
    "address": "Bannerghatta Road",<br/>
    "city": "Bangalore",<br/>
    "zip": "560102",<br/>
    "contact": "Mr. Anil Kumar",<br/>
    "telephone": "+91 98191 23564",<br/>
    "occupancy": "F1",<br/>
    "constructionType": "Type V-A",<br/>
    "numberOfStories": "6",<br/>
    "yearConstructed": "2004"<br/>
    {"}"},<br/>
  "contractorInformation": {"{"}<br/>
    "name": "AZ Builders",<br/>
    "address": "27, Power Lane",<br/>
    "city": "Mysore",<br/>
    "state": "Karnataka",<br/>
    "telephone": "+91 88191 45625",<br/>
    "caLicenseId": "CARF-X56172",<br/>
    "jobId": "XCD-617278",<br/>
    "performedBy": "Mr. Hrishi K"<br/>
    {"}"},
  "ownerInformation": {"{"}<br/>
    "name": "AZ Builders",<br/>
    "address": "27, Power Lane",<br/>
    "city": "Mysore",<br/>
    "state": "Karnataka",<br/>
    "zip" : "561235",<br/>
    "phone" : "+91 91819 17188",<br/>
    "jobId" : "Job XC45",<br/>
    "notes" : "Since 2002"<br/>
    {"}"},<br/>
  "systemRisers": {"{"}<br/>
    "copyToOwner": "On",<br/>
    "copyToOwnerDate": "2022-01-02",<br/>
    "copyToFireAHJ": "On",<br/>
    "copyToFireAHJDate": "2022-02-12",<br/>
    "copyToContractor": "On",<br/>
    "copyToContractorDate": "2022-01-31"<br/>
    {"}"},<br/>
  "formsIncluded": {"{"}<br/>
    "autoSprinkler": "On",<br/>
    "autoSprinklerForms": "0",<br/>
    "autoSprinklerNA": "On",<br/>
    "standpipe": "On",<br/>
    "standpipeForms": "1",<br/>
    "standpipePass": "On"<br/>
    {"}"},<br/>
  "AESForm": [<br/>
    {"{"}
      "item": "#1",<br/>
      "formNumber": "AES 1341",<br/>
      "dateFound": "09-01-22",<br/>
      "dateCorrected": "10-01-22",<br/>
      "deficienciesAndComments": "A valve is replaced"<br/>
    {"}"}
  ]
  {"}"}

</p>    </div>
       }
    </React.Fragment>
)
}

export default LeftComponent