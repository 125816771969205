import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import "../styles/Starter.css";
import {
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  ChangenextScreen,
  next,
  propDetails,
  propertyDetails,
} from "../reducers/dataSplice";
// import { CheckBox } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      // 8E74C7
      backgroundColor: "#fe9b4f",
    },
  },
}));

// https://pdf-filler.dcallistos.com/fill

function PropertyInfo() {
  const [propertyInfo, setPropertyInformation] = useState({
    "name": "Honeywell Building 101",
    "address": "Bannerghatta Road",
    "city": "Bangalore",
    "zip": "560102",
    "contact": "Mr. Anil Kumar",
    "telephone": "+91 98191 23564",
    "occupancy": "F1",
    "constructionType": "Type V-A",
    "numberOfStories": "6",
    "yearConstructed": "2004"
  });
  const nextState = useSelector(next);
  // const navigate=useNavigate();
  const [valid, setValid] = useState("");
  const dispatch = useDispatch();
  const propDetail= useSelector(propDetails)
  // Handles input change event and updates state

  const ChangeHandler = (text, key, value) => {
    if (text === "propertyInfo")
      setPropertyInformation({ ...propertyInfo, [key]: value });
  };

  const validate = async () => {
    if (
      propertyInfo.name === "" ||
      propertyInfo.address === "" ||
      propertyInfo.city === "" ||
      propertyInfo.constructionType === "" ||
      propertyInfo.contact === "" ||
      propertyInfo.numberOfStories === "" ||
      propertyInfo.occupancy === "" ||
      propertyInfo.telephone === "" ||
      propertyInfo.yearConstructed === "" ||
      propertyInfo.zip === ""
    ) {
      setValid("error");
    } else {
      // setStatus("contractor");
      // console.log(propertyInfo);
      dispatch(propertyDetails({ ...propertyInfo }));
      dispatch(ChangenextScreen("contractor"));
    }
  };

  useEffect(()=>{
if(!_.isEmpty(propDetail))
{
setPropertyInformation(propDetail)
}

  },[])

  return (
    <>
      {" "}
      {nextState == "property" && (
        <div className="div-form">
          <Typography id="modal-modal-title" variant="h6"   style={{color:'#70f92c'}}>
            Property Information
          </Typography>
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.name === ""}
            helperText={
              valid === "error" &&
              propertyInfo.name === "" &&
              "Enter valid name"
            }
            required
            className="textfield"
            label="Name"
            variant="outlined"
            value={propertyInfo.name}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "name", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.address === ""}
            helperText={
              valid === "error" &&
              propertyInfo.address === "" &&
              "Enter valid address"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Address"
            variant="outlined"
            value={propertyInfo.address}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "address", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.city === ""}
            helperText={
              valid === "error" &&
              propertyInfo.city === "" &&
              "Enter valid city"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="City"
            variant="outlined"
            value={propertyInfo.city}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "city", e.target.value)
            }
          />
          <TextField
            type="number"
            size="small"
            error={valid === "error" && propertyInfo.zip === ""}
            helperText={
              valid === "error" && propertyInfo.zip === "" && "Enter valid zip"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Zip"
            variant="outlined"
            value={propertyInfo.zip}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "zip", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.contact === ""}
            helperText={
              valid === "error" &&
              propertyInfo.contact === "" &&
              "Enter valid contact"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Contact Person"
            variant="outlined"
            value={propertyInfo.contact}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "contact", e.target.value)
            }
          />
          <TextField
            size="small"
           
            error={valid === "error" && propertyInfo.telephone === ""}
            helperText={
              valid === "error" &&
              propertyInfo.telephone === "" &&
              "Enter valid telephone"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Telephone"
            variant="outlined"
            value={propertyInfo.telephone}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "telephone", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.occupancy === ""}
            helperText={
              valid === "error" &&
              propertyInfo.occupancy === "" &&
              "Enter valid occupancy"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Occupancy"
            variant="outlined"
            value={propertyInfo.occupancy}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "occupancy", e.target.value)
            }
          />
          <TextField
            size="small"
            error={valid === "error" && propertyInfo.constructionType === ""}
            helperText={
              valid === "error" &&
              propertyInfo.constructionType === "" &&
              "Enter valid constructionType"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="ConstructionType"
            variant="outlined"
            value={propertyInfo.constructionType}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "constructionType", e.target.value)
            }
          />
          <TextField
            size="small"
            type="number"
            error={valid === "error" && propertyInfo.numberOfStories === ""}
            helperText={
              valid === "error" &&
              propertyInfo.numberOfStories === "" &&
              "Enter valid numberOfStories"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Number of Stories"
            variant="outlined"
            value={propertyInfo.numberOfStories}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "numberOfStories", e.target.value)
            }
          />
          <TextField
            size="small"
            type="number"
            error={valid === "error" && propertyInfo.yearConstructed === ""}
            helperText={
              valid === "error" &&
              propertyInfo.yearConstructed === "" &&
              "Enter valid yearConstructed"
            }
            required
            sx={{ marginTop: "0.5em" }}
            label="Years Constructed"
            variant="outlined"
            value={propertyInfo.yearConstructed}
            onChange={(e) =>
              ChangeHandler("propertyInfo", "yearConstructed", e.target.value)
            }
          />

          <Button  sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px',marginLeft:'17em',position:'absolute', bottom:10, right:0}}
            onClick={() => {
              validate();
              // setStatus("contractor");
            }}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
}

export default PropertyInfo;
