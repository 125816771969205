import { useState, useRef, useEffect } from "react";
import React from "react";
// import storage from "./firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../styles/Starter.css";
import _ from "lodash";
import {
  Button,
  Card,
  CardContent,
  Typography,

  TextField,
  Checkbox,
} from "@mui/material";


import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReplayIcon from "@mui/icons-material/Replay";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Data from "./Data";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangenextScreen,
 
  formDetail,
 
  formDetails,
 
  next,

  systemDetails,
} from "../reducers/dataSplice";
// import { CheckBox } from "@mui/icons-material";

function FormInfo() {
    const [autoSprinkler, setAutoSprinkler] = useState(true);
    const [autoSprinklerNA, setAutoSprinklerNA] = useState(true);
    const [standpipe, setStandpipe] = useState(true);
    const [standpipePass, setStandpipePass] = useState(true);
    const [formInfo, setFormInfo] = useState({
      autoSprinkler: false,
      autoSprinklerForms: "0",
      autoSprinklerNA: false,
      standpipe: false,
      standpipeForms: "1",
      standpipePass: false,
    });
    const [valid,setValid]=useState('')
  const nextState = useSelector(next);

  const dispatch = useDispatch();
  // Handles input change event and updates state
  const formD = useSelector(formDetail)
  useEffect(()=>{
    if(!_.isEmpty(formD))
    {
    setFormInfo(formD)
    let x = { ...formD };
     
    x.autoSprinkler=='On'?setAutoSprinkler(true):setAutoSprinkler(false);
    x.standpipe=='On'?setStandpipe(true):setStandpipe(false);
    x.standpipePass=='On'?setStandpipePass(true):setStandpipePass(false);
    x.autoSprinklerNA=='On'?setAutoSprinklerNA(true):setAutoSprinklerNA(false);
    setFormInfo(x);
    }
  },[])

  const ChangeHandler = (text, key, value) => {
    if (text === "formInfo")
    setFormInfo({ ...formInfo, [key]: value });
  };

  const validate = async () => {
   formInfo.autoSprinkler=autoSprinkler?'On':'Off';
   formInfo.autoSprinklerNA=autoSprinklerNA?'On':'Off';
   formInfo.standpipe=standpipe?'On':'Off';
   formInfo.standpipePass=standpipePass?'On':'Off';
   if(formInfo.autoSprinklerForms==='' || formInfo.standpipeForms==='')
   {
    setValid("error");
   }
   else{
    dispatch(formDetails({ ...formInfo }));
    dispatch(ChangenextScreen("aes"));
   }
      
  };

  return (
    <>
      {" "}
      {nextState === "form" && (
        <div className="div-form" style={{marginLeft:'30%'}}>
         <Typography id="modal-modal-title" variant="h6" style={{color:'#70f92c'}}>
                  Forms Included
                </Typography>
                <div style={{marginBottom:'1em'}}> AutoSprinkler&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <Checkbox
                  name="autoSprinkler"
                  label="autoSprinkler"
                  value={autoSprinkler}
                  checked={autoSprinkler}
                  // defaultChecked
                  onChange={(e) => {
                    setAutoSprinkler(!autoSprinkler);
                    ChangeHandler("formInfo", "autoSprinkler", autoSprinkler);
                  }}
                ></Checkbox></div>
                
                <div style={{marginBottom:'1em'}}>
                autoSprinklerNA{" "}
                <Checkbox
                  name="autoSprinklerNA"
                  label="autoSprinklerNA"
                  value={autoSprinklerNA}
                 checked={autoSprinklerNA}
                  // defaultChecked
                  onChange={(e) => {
                    setAutoSprinklerNA(!autoSprinklerNA);
                    ChangeHandler(
                      "formInfo",
                      "autoSprinklerNA",
                      autoSprinklerNA
                    );
                  }}
                ></Checkbox>
                </div>
                <div style={{marginBottom:'1em'}}>
                Standpipe &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Checkbox
                  name="standpipe"
                  label="standpipe"
                  value={standpipe}
                  checked={standpipe}
                  // defaultChecked
                  onChange={(e) => {
                    setStandpipe(!standpipe);
                    ChangeHandler("formInfo", "standpipe", standpipe);
                  }}
                ></Checkbox>
                </div>
                <div style={{marginBottom:'1em'}}>
                Standpipe Pass &nbsp;&nbsp;

                <Checkbox
                  name="standpipePass"
                  label="standpipePass"
                  value={standpipePass}
                  checked={standpipePass}
                  // defaultChecked
                  onChange={(e) => {
                    setStandpipePass(!standpipePass);
                    ChangeHandler("formInfo", "standpipePass", standpipePass);
                  }}
                ></Checkbox>
                </div>
                <TextField
                  size="small"
                  error={
                    valid === "error" && formInfo.autoSprinklerForms === ""
                  }
                  helperText={
                    valid === "error" &&
                    formInfo.autoSprinklerForms === "" &&
                    "Enter valid address"
                  }
                  required
                  sx={{ marginTop: "0.5em" , width:'300px' }}
                  label="Address"
                  variant="outlined"
                  value={formInfo.autoSprinklerForms}
                  onChange={(e) =>
                    ChangeHandler(
                      "formInfo",
                      "autoSprinklerForms",
                      e.target.value
                    )
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && formInfo.standpipeForms === ""}
                  helperText={
                    valid === "error" &&
                    formInfo.standpipeForms === "" &&
                    "Enter valid standpipeForms"
                  }
                  required
                  sx={{ marginTop: "0.5em", width:'300px' }}
                  label="standpipeForms"
                  variant="outlined"
                  value={formInfo.standpipeForms}
                  onChange={(e) =>
                    ChangeHandler("formInfo", "standpipeForms", e.target.value)
                  }
                />
                
                <div style={{display:'flex', flexDirection:'row',marginLeft:'5em',position:'absolute', bottom:10, right:0}}>
                <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'-1.5em'}} onClick={() => dispatch(ChangenextScreen("system"))}>Back</Button>
                <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'0.5em'}} onClick={() => validate()}>Next</Button>
                </div>
          
        </div>
      )}
    </>
  );
}

export default FormInfo;
