import { useState, useRef, useEffect } from "react";
import React from "react";
import "../styles/Starter.css";
import _ from "lodash";
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  aesDetail,
  aesDetails,
  ChangenextScreen,
  next,
} from "../reducers/dataSplice";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      // padding: 0,
      width: 200,
    },
  },
});

function AesInfo() {
  const [aesInfo, setAesInfo] = useState({
    item: "#1",
    formNumber: "AES 1341",
    dateFound: "09-01-22",
    dateCorrected: "10-01-22",
    deficienciesAndComments: "A valve is replaced",
  });
  const [count,setCount]=useState(0)
  const [dateFound, setdateFound] = useState("10-03-22");
  const [dateCorrected, setdateCorrected] = useState("10-30-22");
  const [item, setItem] = useState("#2");
  const [formNumber, setFormNumber] = useState("AES 1343");
  const [deficienciesAndComments, setDeficienciesAndComments] =
    useState("Sprinkler serviced");
  const [valid, setValid] = useState("");
  const nextState = useSelector(next);
  const classes = useStyles();
  const [indexKey, setIndexKey] = useState(0);
  const [edit, setEdit] = useState(true);
  const [fullDetails, setFullDetails] = useState([
    {
      item: "#1",
      formNumber: "AES 1341",
      dateFound: "09-01-22",
      dateCorrected: "10-01-22",
      deficienciesAndComments: "A valve is replaced",
    },
    {
      item: "#2",
      formNumber: "AES 1343",
      dateFound: "10-03-22",
      dateCorrected: "10-30-22",
      deficienciesAndComments: "Sprinkler serviced",
    },
  ]);

  // console.log(Object.isExtensible(fullDetails))
  useEffect(() => {
    if (Object.isExtensible(fullDetails)) {
      Object.defineProperties(fullDetails, {
        item: {
          value: "#2",
          writable: true,
        },
        formNumber: {
          value: "AES 1343",
          writable: true,
        },
      });
    }
  }, []);
  const dispatch = useDispatch();
  const aesD = useSelector(aesDetail);
  useEffect(() => {
    if (!_.isEmpty(aesD)) {
      setAesInfo(aesD);
      // console.log(aesInfo)
      setFullDetails(aesD);
      // console.log(aesD)
      // console.log(fullDetails)
      setItem(aesD[fullDetails.length - 1]["item"]);
      setFormNumber(aesD[fullDetails.length - 1]["formNumber"]);
      setDeficienciesAndComments(
        aesD[fullDetails.length - 1]["deficienciesAndComments"]
      );
      setEdit(false);
    }
  }, [aesD]);

  // Handles input change event and updates state
  let add = false;

  function matchesEl(el) {
    // console.log(el.item === '' && el.formNumber === '')
    return el.item !== '' && el.formNumber !== '';
}
  const validate = async () => {
   
    // console.log(fullDetails.splice(2))
    fullDetails.map(x=>{
      if (
        x.item === "" ||
        x.formNumber === "" ||
        x.dateFound === "" ||
        x.dateCorrected === "" ||
        x.deficienciesAndComments === ""
      ) {
        setValid("error");
      }
    })

      if (!add && valid!=="error") {
      // console.log(fullDetails.length,count)
      // console.log(fullDetails)
      let x=[...fullDetails];

      // .splice(3);
      
      // console.log(y)
count>0 && x.splice(fullDetails.length+1-count)
    // x=  x.findIndex(matchesEl)?x.splice(x.findIndex(matchesEl), 1):x
    //   let i=1;
    //   while(i<=fullDetails.length)
    //  { 
    //   let y = x.findIndex(matchesEl)
    //   if(!y)
    //   x.splice(x.findIndex(matchesEl), 1);
    //   console.log(x,i)
    //   i=i+1;
    // }
      setFullDetails(x);
      // console.log(fullDetails.splice( fullDetails.length+1-count))
      // console.log(x, fullDetails)
      setValid("corrected");
      dispatch(aesDetails([...x]));
      dispatch(ChangenextScreen("final"));
    }
  };

  const addFormFields = () => {
    setCount(count+1);
    add = true;
    // validate();

    // setFullDetails
    if (valid !== "error") {
      setFullDetails([
        ...fullDetails,
        {
          item: "",
          formNumber: "",
          dateFound: "2022-12-16",
          dateCorrected: "2022-12-16",
          deficienciesAndComments: "",
        },
      ]);
    }
  };

  const handleChange = (i, e, key) => {
    setAesInfo({ ...aesInfo, [key]: e });
    if (_.isEmpty(aesD)) {
      // console.log("first")
      // let newFormValues = [...fullDetails];
      let newFormValues= JSON.parse(JSON.stringify(fullDetails))
      newFormValues[i][key] = e;
      setFullDetails(newFormValues);
    }
   

    if (!_.isEmpty(aesD)) {
      // console.log("Here");
      let clonedArray = JSON.parse(JSON.stringify(fullDetails));
      // console.log(clonedArray)
      clonedArray[i][key] = e;
      setFullDetails(clonedArray);
    }
  };

  const getDeleteRow = (keys) => {
    // console.log(keys);
    if (fullDetails.length > 1) {
      let newFormValues = [...fullDetails];
      newFormValues.splice(keys, 1);
      setFullDetails(newFormValues);
    } else {
      setItem("");
      setFormNumber("");
      setDeficienciesAndComments("");
      setdateCorrected("2022-12-16");
      setdateFound("2022-12-16");
      setFullDetails([
        {
          item: "",
          formData: "",
          deficienciesAndComments: "",
          dateFound: "2022-12-16",
          dateCorrected: "2022-12-16",
        },
      ]);
    }

    // fullDetails.splice();
  };

  return (
    <>
      {nextState === "aes" && (
        <div className="div-form" style={{marginLeft:'1em'}}>
          <Typography id="modal-modal-title" variant="h6" style={{color:'#70f92c'}}>
            AES Forms
          </Typography>

          <div className="div-form">
            {
              // fullDetails.length > 0 &&
              fullDetails.map((i, key) => (
                <>
                  {
                  // key <= fullDetails.length / 2 &&
                   (
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      key={key}
                    >
                      <TextField
                        size="small"
                        error={valid === "error" && i.item === ""}
                        helperText={
                          valid === "error" &&
                          i.item === "" &&
                          "Enter valid item"
                        }
                        required
                        sx={{ marginTop: "0.5em", width: "200px" }}
                        label="Item"
                        variant="outlined"
                        // onClick={}
                        value={i.item}
                        onChange={(e) => {
                          handleChange(key, e.target.value, "item");
                          setItem(e.target.value);
                        }}
                      />
                      <TextField
                        size="small"
                        // type="number"
                        error={valid === "error" && i.formNumber === ""}
                        helperText={
                          valid === "error" &&
                          i.formNumber === "" &&
                          "Enter valid formNumber"
                        }
                        required
                        sx={{
                          marginTop: "0.5em",
                          width: "200px",
                          marginBottom: "1em",
                          marginLeft: "1em",
                          marginLRight: "1em",
                        }}
                        label="formNumber"
                        variant="outlined"
                        value={i.formNumber}
                        onChange={(e) => {
                          handleChange(key, e.target.value, "formNumber");
                          setFormNumber(e.target.value);
                        }}
                      />
                      <div style={{ width: "1em" }}></div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          className={classes.root}
                          label="Date Found"
                          inputFormat="YYYY-MM-DD"
                          value={i.dateFound}
                          onChange={(e) => {
                            // console.log(e.format("YYYY-MM-DD").toString());
                            setdateFound(e.format("YYYY-MM-DD").toString());

                            handleChange(
                              key,
                              e.format("YYYY-MM-DD").toString(),
                              "dateFound"
                            );
                            // ChangeHandler("aesInfo", "dateFound", dateFound);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <div style={{ height: "1em", width: "1em" }}></div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          className={classes.root}
                          label="Date Corrected"
                          inputFormat="YYYY-MM-DD"
                          value={i.dateCorrected}
                          onChange={(e) => {
                            handleChange(
                              key,
                              e.format("YYYY-MM-DD").toString(),
                              "dateCorrected"
                            );
                            setdateCorrected(e.format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <TextField
                        size="small"
                        error={
                          valid === "error" && i.deficienciesAndComments === ""
                        }
                        helperText={
                          valid === "error" &&
                          i.deficienciesAndComments === "" &&
                          "Enter valid deficienciesAndComments"
                        }
                        required
                        sx={{
                          marginTop: "0.5em",
                          width: "200px",
                          marginLeft: "1em",
                        }}
                        label="deficienciesAndComments"
                        variant="outlined"
                        value={i.deficienciesAndComments}
                        onChange={(e) => {
                          handleChange(
                            key,
                            e.target.value,
                            "deficienciesAndComments"
                          );
                          setDeficienciesAndComments(e.target.value);
                        }}
                      />{" "}
                      {/* {edit ? (
                        <Button
                          onClick={() => {
                            handleChange();
                            setIndexKey(key);
                          }}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setEdit(true);
                            setIndexKey(key);
                          }}
                        >
                          Edit
                        </Button>
                      )} */}
                    </div>
                  )}
                </>
              ))
            }
          </div>

          <div
            style={{ display: "flex", flexDirection: "row",position:'absolute', bottom:40, right:0 , marginLeft:'40em',width:'400px'}}
          >
            <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'200px', marginLeft:'3em'}}
              onClick={() => {
                addFormFields();
                setEdit(true);
                
              }}
            >
              Add Another
            </Button>
            <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'1.5em'}} onClick={() => dispatch(ChangenextScreen("form"))}>
              Back
            </Button>
            <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'1.5em'}}
              onClick={() => {
                add = false;
                // handleChange();
                validate();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default AesInfo;
