import React from "react";
import '../styles/Data.css';
import RightComponent from "./RightComponent";
import LeftComponent from "./LeftComponent";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Data=()=>{
const navigate=useNavigate()
    return(
    <React.Fragment>
        <Button sx={{ marginTop:'1em',padding:'0.5em', width:'250px',marginLeft:'-1em'}} onClick={()=>navigate(-1)}>{"<"} Back to Home page</Button>
        <div className="data-container">
            
       <div className="left-component"><LeftComponent /></div>
       <div className="right-component"><RightComponent/></div>
       </div>
    </React.Fragment>
)
}

export default Data