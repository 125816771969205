import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Data from "./components/Data";
import Starter from "./components/Starter";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Starter />} />
        <Route path="/data" element={<Data />} />
      </Routes>
    </Router>
  );
}

export default App;
