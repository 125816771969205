import React, { useEffect, useState } from "react";
import "../styles/RightComponent.css";
import filled from "../assets/filled_form.pdf";
import unfilled from "../assets/formsincorpbyreferencefinal.pdf";
import { Button } from "@mui/material";

const RightComponent = () => {
  const [fill, setFill] = useState(false);
  return (
    <React.Fragment>
      <h3>Sample PDF</h3>
      
      <Button sx={{border:'1.5px solid #70f92c',padding:'0.5em', width:'150px',color:'#000',fontWeight:'600'}}onClick={() => setFill(!fill)} style={{marginTop:'-3em',position:'absolute',marginLeft:'49%',fontSize:'16px'}}>{!fill?'Fill PDF':'Unfill PDF'}</Button>
      {!fill && (
        <iframe src={unfilled} title="doc" className="iframe-grid"></iframe>
      )}
      {fill && (
        <iframe src={filled} title="doc" className="iframe-grid"></iframe>
      )}
    </React.Fragment>
  );
};

export default RightComponent;
