import { useState, useRef, useEffect } from "react";
import React from "react";
import "../styles/Starter.css";
import {
  Button,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangenextScreen,
  next,
  sysDetails,
  systemDetails,
} from "../reducers/dataSplice";
import _ from "lodash";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      // padding: 0,
      width: 300,
    },
  },
});
function SystemInfo() {
  const [copyToOwner, setCopyToOwner] = useState(true);
  const [copyToFireAHJ, setCopyToFireAHJ] = useState(true);
  const [copyToContractor, setCopyToContractor] = useState(true);
  const [copyToOwnerDate, setCopyToOwnerDate] = useState("2022-01-02");
  const [copyToFireAHJDate, setcopyToFireAHJDate] = useState("2022-02-12");
  const [copyToContractorDate, setcopyToContractorDate] =
    useState("2022-01-31");
  const classes = useStyles();
  const [systemInfo, setSystemInfo] = useState({
    copyToOwner: false,
    copyToOwnerDate: "",
    copyToFireAHJ: false,
    copyToFireAHJDate: "",
    copyToContractor: false,
    copyToContractorDate: "",
  });
  const nextState = useSelector(next);

  const dispatch = useDispatch();
  // Handles input change event and updates state
  const sysD = useSelector(sysDetails);
  useEffect(() => {
    if (!_.isEmpty(sysD)) {
      setSystemInfo(sysD);
     
      let x = { ...sysD };
     
      x.copyToOwner=='On'?setCopyToOwner(true):setCopyToOwner(false);
      x.copyToContractor=='On'?setCopyToContractor(true):setCopyToContractor(false);
      x.copyToFireAHJ=='On'?setCopyToFireAHJ(true):setCopyToFireAHJ(false);
      setCopyToOwnerDate(x.copyToOwnerDate);
      setcopyToContractorDate(x.copyToContractorDate);
      setcopyToFireAHJDate(x.copyToFireAHJDate);
      setSystemInfo(x);
    
    }
  }, []);

  const ChangeHandler = (text, key, value) => {
    if (text === "systemInfo") setSystemInfo({ ...systemInfo, [key]: value });
  };

  const validate = async () => {
   
    let systemInfo2 = {};
    systemInfo2.copyToOwner = copyToOwner ? "On" : "Off";
    systemInfo2.copyToContractor = copyToContractor ? "On" : "Off";
    systemInfo2.copyToFireAHJ = copyToFireAHJ ? "On" : "Off";
    systemInfo2.copyToOwnerDate = copyToOwnerDate;
    systemInfo2.copyToFireAHJDate = copyToFireAHJDate;
    systemInfo2.copyToContractorDate = copyToContractorDate;
    setSystemInfo(systemInfo2);

    
    dispatch(systemDetails({ ...systemInfo2 }));
    dispatch(ChangenextScreen("form"));
  };

  return (
    <>
      {" "}
      {nextState === "system" && (
        <div className="div-form" style={{marginLeft:'30%'}}>
          <Typography id="modal-modal-title" variant="h6" style={{color:'#70f92c'}}>
            System Risers
          </Typography>
          <div style={{  marginBottom: "1em" }}>
            {" "}
            CopyToOwner &nbsp;&nbsp;&nbsp;
            <Checkbox
              name="CopyToOwner"
              label="copyToOwner"
              value={copyToOwner}
              checked={copyToOwner}
              // defaultChecked
              onChange={(e) => {
                setCopyToOwner(!copyToOwner);
                ChangeHandler("systemInfo", "copyToOwner", copyToOwner);
              }}
            ></Checkbox>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              className={classes.root}
              label="Copy To Owner Date"
              inputFormat="YYYY-MM-DD"
              value={copyToOwnerDate}
              onChange={(e) => {
                setCopyToOwnerDate(e.format("YYYY-MM-DD"));
                ChangeHandler("systemInfo", "copyToOwnerDate", copyToOwnerDate);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div style={{ marginBottom: "1em", marginTop: "1em" }}>
            {" "}
            CopyToFireAHJ &nbsp;&nbsp;
            <Checkbox
              name="CopyToFireAHJ"
              label="CopyToFireAHJ"
              value={copyToFireAHJ}
              checked={copyToFireAHJ}
              // defaultChecked
              onChange={(e) => {
                setCopyToFireAHJ(!copyToFireAHJ);
                ChangeHandler("systemInfo", "CopyToFireAHJ", copyToFireAHJ);
              }}
            ></Checkbox>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              className={classes.root}
              label="copy To Fire AHJ Date"
              inputFormat="YYYY-MM-DD"
              value={copyToFireAHJDate}
              onChange={(e) => {
                setcopyToFireAHJDate(e.format("YYYY-MM-DD"));
                ChangeHandler(
                  "systemInfo",
                  "copyToFireAHJDate",
                  copyToFireAHJDate
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div style={{ marginBottom: "1em", marginTop: "1em" }}>
            copyToContractor
            <Checkbox
              name="copyToContractor"
              label="copyToContractor"
              value={copyToContractor}
              // defaultChecked
              checked={copyToContractor}
              onChange={(e) => {
                setCopyToContractor(!copyToContractor);
                ChangeHandler(
                  "systemInfo",
                  "copyToContractor",
                  copyToContractor
                );
              }}
            ></Checkbox>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              className={classes.root}
              label="Copy To Contractor Date"
              inputFormat="YYYY-MM-DD"
              value={copyToContractorDate}
              onChange={(e) => {
                setcopyToContractorDate(e.format("YYYY-MM-DD"));
                ChangeHandler(
                  "systemInfo",
                  "copyToContractorDate",
                  copyToContractorDate
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: "5em",position:'absolute', bottom:10, right:0 }}
          >
            <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px',marginLeft:'-1em'}} onClick={() => dispatch(ChangenextScreen("owner"))}>
              Back
            </Button>
            <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'0.5em'}} onClick={() => validate()}>Next</Button>
          </div>
        </div>
      )}
    </>
  );
}

export default SystemInfo;
