import { useState, useRef, useEffect } from "react";
import React from "react";
import _ from "lodash";
import "../styles/Starter.css";
import {
  Button,
  Card,
  CardContent,
  Typography,

  TextField,
} from "@mui/material";



import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Data from "./Data";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangenextScreen,
  contractDetails,
  contractorDetails,
  next,
  ownDetails,
  ownerDetails,
  propertyDetails,
} from "../reducers/dataSplice";
// import { CheckBox } from "@mui/icons-material";

function OwnerInfo() {
    const [ownerInfo, setOwnerInformation] = useState({
      "name": "AZ Builders",
      "address": "27, Power Lane",
      "city": "Mysore",
      "state": "Karnataka",
      "zip" : "561235",
      "phone" : "+91 91819 17188",
      "jobId" : "Job XC45",
      "notes" : "Since 2002"
      });
  const nextState = useSelector(next);
  // const navigate=useNavigate();
  const [valid, setValid] = useState("");
  const dispatch = useDispatch();
  const ownD=useSelector(ownDetails)
  // Handles input change event and updates state

  const ChangeHandler = (text, key, value) => {
    if (text === "ownerInfo")
    setOwnerInformation({ ...ownerInfo, [key]: value });
  };

  const validate = async () => {
    if (
      ownerInfo.name === "" ||
      ownerInfo.address === "" ||
      ownerInfo.city === "" ||
      ownerInfo.state === "" ||
      ownerInfo.zip === "" ||
      ownerInfo.phone === "" ||
      ownerInfo.jobId === "" ||
      ownerInfo.notes === "" 
    ) {
      setValid("error");
    } else {

      dispatch(ownerDetails({ ...ownerInfo }));
      dispatch(ChangenextScreen("system"));
    }
  };

  useEffect(()=>{
    if(!_.isEmpty(ownD))
    {
    setOwnerInformation(ownD)
    }
  },[])

  return (
    <>
      {" "}
      {nextState == "owner" && (
        <div className="div-form">
          <Typography id="modal-modal-title" variant="h6" style={{color:'#70f92c'}}>
                  Owner Information
                </Typography>
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.name === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.name === "" &&
                    "Enter valid name"
                  }
                  required
                  className="textfield"
                  label="Name"
                  variant="outlined"
                  value={ownerInfo.name}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "name", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.address === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.address === "" &&
                    "Enter valid address"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="Address"
                  variant="outlined"
                  value={ownerInfo.address}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "address", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.city === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.city === "" &&
                    "Enter valid city"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="City"
                  variant="outlined"
                  value={ownerInfo.city}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "city", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.state === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.state === "" &&
                    "Enter valid state"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="State"
                  variant="outlined"
                  value={ownerInfo.state}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "state", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.zip === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.zip === "" &&
                    "Enter valid zip number"
                  }
                  required
                  type="number"
                  sx={{ marginTop: "0.5em" }}
                  label="zip"
                  variant="outlined"
                  value={ownerInfo.zip}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "zip", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  // type="number"
                  error={valid === "error" && ownerInfo.phone === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.phone === "" &&
                    "Enter valid phone number"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="Phone"
                  variant="outlined"
                  value={ownerInfo.phone}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "phone", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.jobId === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.jobId === "" &&
                    "Enter valid jobId number"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="JobId"
                  variant="outlined"
                  value={ownerInfo.jobId}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "jobId", e.target.value)
                  }
                />
                <TextField
                  size="small"
                  error={valid === "error" && ownerInfo.notes === ""}
                  helperText={
                    valid === "error" &&
                    ownerInfo.notes === "" &&
                    "Enter valid notes number"
                  }
                  required
                  sx={{ marginTop: "0.5em" }}
                  label="Notes"
                  variant="outlined"
                  value={ownerInfo.notes}
                  onChange={(e) =>
                    ChangeHandler("ownerInfo", "notes", e.target.value)
                  }
                />
                <div style={{display:'flex', flexDirection:'row',margin:'auto',position:'absolute', bottom:10, right:0}}>
                <Button onClick={() => dispatch(ChangenextScreen("contractor"))} sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px'}}>Back</Button>
                <Button sx={{border:'1px solid #7EC4F8', marginTop:'1em',padding:'0.5em', width:'100px', marginLeft:'0.5em'}} onClick={() => validate()}>Next</Button>
                </div>
          
        </div>
      )}
    </>
  );
}

export default OwnerInfo;
